import React, { Component } from 'react';

import './style.css';
import Text from '../../atoms/Text';
import Link from '../../atoms/Link';
import Button from '../../atoms/Button';
import Whitespace from '../../atoms/Whitespace';

class Story extends Component {
    render () {
        return <div className="story">
            <Whitespace classes="pb-1 pr-1">
                <Text type="title" value="JavaScript Development For Your Startup" />
            </Whitespace>
            <Text type="title1" value="Let's build your SaaS from start to finish" />
                <Whitespace classes="pt-2 story-mobile">
                    <Button cta={true}>
                        <Link type="white" value="Let's Talk" href="#cta"/>
                    </Button>
                    <Whitespace classes="pl-2 story-link-mobile">
                        <Link value="What you can do?" href="#skills"/>
                    </Whitespace>
                </Whitespace>
        </div>
    }
}

export default Story;