import React, { Component } from 'react';
import './style.css'

export default class Link extends Component {
    
    render () {
        var classList = ["link"];
        if (this.props.type) {
            classList.push(`link-${this.props.type}`);
        }

        if (this.props.white) {
            classList.push("text-white");
        }

        return <a 
            className={classList.join(" ")}
            href={this.props.href}
        >
            {this.props.value}
        </a>
    }
}