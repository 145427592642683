import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StoryImage from '../../../icons/story-image.svg';
import './style.css';

class StoryShowcase extends Component {
    render () {
        return <div className="story-showcase">
            <StoryImage 
                name="story-showcase" 
                // src="./story-image.png"
                className="story-showcase-image" 
                alt="Showcase"
            />
            <FontAwesomeIcon 
                className="story-icon story-icon1" 
                icon={["fab", "react"]} 
                style={{color: '#00DCFF'}}
            />
            <FontAwesomeIcon 
                className="story-icon story-icon2" 
                icon={["fab", "js-square"]} 
                style={{color: '#F7E500'}}
            />
            <FontAwesomeIcon 
                className="story-icon story-icon3" 
                icon={["fab", "node-js"]} 
                style={{color: '#4EA564'}}
            />
            <FontAwesomeIcon 
                className="story-icon story-icon4" 
                icon={["fab", "html5"]} 
                style={{color: '#F83200'}}
            />
            <FontAwesomeIcon 
                className="story-icon story-icon5" 
                icon={["fab", "css3-alt"]} 
                style={{color: '#3F29E7'}}
            />
            <FontAwesomeIcon 
                className="story-icon story-icon6" 
                icon={["fab", "git-alt"]} 
                style={{color: '#FF3400'}}
            />
        </div>
    }
}

export default StoryShowcase;