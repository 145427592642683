import React, { Component } from 'react';
import './style.css';

import Logo from '../../molecules/Logo';
import Nav from '../../molecules/Nav';
import Link from '../../atoms/Link';
import Button from '../../atoms/Button';

class Header extends Component {
    render () {
        return <div className="header">
            <Logo title="AcumenApps" />
            <Nav>
                <Link type="nav" value="Home" href="#home"/>
                <Link type="nav" value="What you can do?" href="#skills"/>
                <Link type="nav" value="Examples" href="#examples"/>
                <Button>
                    <Link type="white" value="Let's Talk" href="#cta"/>
                </Button>
            </Nav>
        </div>
    }
}

export default Header;