import React, { Component } from 'react';
import './style.css'

export default class Card extends Component {
    render () {
        var classList = ["card"];
        if (this.props.partial) {
            classList.push("card-partial")
        }

        if (this.props.normal) {
            classList.push("card-normal");
        }

        if (this.props.shadow) {
            classList.push("card-shadow");
        }

        if (this.props.class) {
            classList.push(this.props.class);
        }

        return <div 
            className={classList.join(" ")}
            style={{
                height: this.props.height, 
                width: this.props.width,
                background: this.props.background,
                backgroundImage: this.props.backgroundImage ? `url(${this.props.backgroundImage})` : 'none'
            }}
        >
            {this.props.children}
        </div>
    }
}