import React, { Component } from 'react';

import Card from '../../atoms/Card';
import Text from '../../atoms/Text';
import './style.css';

export default class SectionHeading extends Component {
    render () {
        return <Card class="section-heading">
            <Text type="title2" light={true} value={this.props.name} />
        </Card>
    }
}