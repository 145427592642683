import React, { Component } from 'react';
import './style.css'

export default class Input extends Component {
    render () {
        return <input 
            className={`input ${this.props.classes}`}
            placeholder={this.props.placeholder}
            id={this.props.id}
            type={this.props.type}
            name={this.props.name}
            value={this.props.value}
            onChange={this.props.onChange}
        />
    }
}