import React from "react"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faReact, faJsSquare, faNodeJs, faHtml5, faCss3Alt, faGitAlt } from '@fortawesome/free-brands-svg-icons'

import '../components/variables/default.css'; 
import DefaultLayout from "../components/layouts/DefaultLayout";
import Header from "../components/organisms/Header";
import Hero from "../components/organisms/Hero";
import SectionHeading from "../components/molecules/SectionHeading";
import Whitespace from '../components/atoms/Whitespace';
import SkillFeature from "../components/organisms/SkillFeature";
import CallToAction from "../components/organisms/CallToAction";
import ExampleFeature from "../components/organisms/ExampleFeature";
import Footer from "../components/organisms/Footer";

import DesignIcon from '../icons/design_services.svg';
import DevicesIcon from '../icons/devices.svg';
import SmartButtonIcon from '../icons/smart_button.svg';
import CodeIcon from '../icons/code.svg';
import CloudDoneIcon from '../icons/cloud_done.svg';

import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

library.add(faReact, faJsSquare, faNodeJs, faHtml5, faCss3Alt, faGitAlt)

const IndexPage = ({data}) => {
  const UIUXDesignImg = getImage(data.uiuxdesign3)
  const FrontEndDesignImg = getImage(data.frontenddesign)
  const FrontEndDevImg = getImage(data.frontenddev)
  const BackEndDevImg = getImage(data.backenddev2)
  const DeploymentImg = getImage(data.deployment2)
  const LandingPageBuilderImg = getImage(data.landingpagebuilder)
  const WeatherDashboardImg = getImage(data.weatherdashboard)
  return <DefaultLayout>
    <Header></Header>
    <div id="home">
      <Hero />
    </div>
    <div id="skills">
      <Whitespace classes="pb-4">
        <SectionHeading name="FULL STACK DEVELOPMENT" />
      </Whitespace>
    </div>
    <SkillFeature 
      type="left"  
      title="UI/UX Design" 
      body="Take user stories from your customers and convert them to high fidelity design"
      StoryImage={UIUXDesignImg}
    >
      <DesignIcon style={{width: 36, height: 36}}/>
    </SkillFeature>
    <SkillFeature 
      type="right" 
      title="Frontend design" 
      body="Convert the design to real code to display in browser using HTML, CSS, and atomic design principles"
      StoryImage={FrontEndDesignImg}
    >
      <DevicesIcon style={{width: 32, height: 32}}/>
    </SkillFeature>
    <SkillFeature 
      type="left" 
      title="Frontend development" 
      body="Use frameworks like React, Angular, RxJS, Redux, etc. to make your app work in browser"
      StoryImage={FrontEndDevImg}
    >
      <SmartButtonIcon style={{width: 36, height: 36}}/>
    </SkillFeature>
    <SkillFeature 
      type="right" 
      title="Backend development" 
      body="Make your app do real work using backend technologies like Nodejs, Firebase, MongoDB, SQL, etc."
      StoryImage={BackEndDevImg}
    >
      <CodeIcon style={{width: 36, height: 36}}/>
    </SkillFeature>
    <SkillFeature 
      type="left" 
      last={true} 
      title="Deployment" 
      body="Deploy your app and setup CI/CD using Nginx, Git, AWS, DigitalOcean, Now, etc."
      StoryImage={DeploymentImg}
    >
      <CloudDoneIcon style={{width: 36, height: 36}}/>
    </SkillFeature>
    <div id="examples">
      <Whitespace classes="pb-4">
        <SectionHeading name="EXAMPLES" />
      </Whitespace>
    </div>
    <ExampleFeature 
      title="Landing Page Builder"
      body="A web app for creating beautiful landing pages quickly"
      skills={[
        {id: 1, name: 'JavaScript'},
        {id: 2, name: 'React'},
        {id: 3, name: 'HTML'},
        {id: 4, name: 'CSS'},
        {id: 5, name: 'NodeJS'},
        {id: 6, name: 'Firebase'},
        {id: 7, name: 'MongoDB'},
        {id: 8, name: 'DigitalOcean'},
        {id: 9, name: 'Now'},
      ]}
      image={LandingPageBuilderImg}
      left={true}
    />
    <ExampleFeature 
      title="Weather Dashboard"
      body="A simple weather app to get quick insights on weather in coming weeks"
      skills={[
        {id: 1, name: 'JavaScript'},
        {id: 2, name: 'React'},
        {id: 3, name: 'HTML'},
        {id: 4, name: 'CSS'},
        {id: 5, name: 'APIs'},
        {id: 6, name: 'Firebase'},
      ]}
      image={WeatherDashboardImg}
      last={true}
    />
    <div id="cta">
      <Whitespace classes="pb-4">
        <SectionHeading name="GREAT PRODUCTS START WITH A GREAT TEAM" />
      </Whitespace>
    </div>
    <CallToAction />
    <Footer />
  </DefaultLayout>
}

export default IndexPage

export const pageQuery = graphql`
  query GetImages {
    uiuxdesign3:file(relativePath: {eq: "ui-ux-design3.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    frontenddesign:file(relativePath: {eq: "frontend-design.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    frontenddev:file(relativePath: {eq: "frontend-dev.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    backenddev2:file(relativePath: {eq: "backend-dev2.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    deployment2:file(relativePath: {eq: "deployment2.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    landingpagebuilder:file(relativePath: {eq: "landing-page-builder.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    weatherdashboard:file(relativePath: {eq: "weather-dashboard.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`