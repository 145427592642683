import React, { Component } from 'react';
import './style.css'

export default class Text extends Component {
    
    render () {
        var classList = ["text"];
        if (this.props.type) {
            classList.push(`text-${this.props.type}`);
        }

        if (this.props.white) {
            classList.push("text-white");
        }

        if (this.props.light) {
            classList.push("text-light");
        }

        if (this.props.gray) {
            classList.push("text-gray");
        }

        if (this.props.dark) {
            classList.push("text-dark");
        }
        
        if (this.props.onClick) {
            classList.push("text-link");
        }
        return <span 
            className={classList.join(" ")}
        >
            {this.props.value}
        </span>
    }
}