import React, { Component } from 'react';
import Bubble from '../../atoms/Bubble';
import Text from '../../atoms/Text';

export default class Skill extends Component {
    render () {
        return <Bubble>
            <Text type="caption" light={true} value={this.props.name} />
        </Bubble>
    }
}