import React, { Component } from 'react';

import Example from '../../molecules/Example';
import Image from '../../atoms/Image';
import Card from '../../atoms/Card';
import './style.css';

export default class ExampleFeature extends Component {
    render () {
        const { left, last, title, body, skills, image } = this.props;
        let classList = ["example-feature"];

        if (left) {
            classList.push("example-feature-left");
        }

        if (last) {
            classList.push("example-feature-last");
        }

        return <div className={classList.join(" ")}>
            <Card 
                shadow={true}
                class="example-image"
            >
                <Image 
                    name="imagework"
                    source={image}
                />
            </Card>
            <Example 
                title={title}
                body={body}
                skills={skills}
            />  
        </div>
    }
}