import React, { Component } from 'react';

import Card from '../../atoms/Card';
import Text from '../../atoms/Text';
import './style.css';
import Whitespace from '../../atoms/Whitespace';

export default class SkillShowcase extends Component {
    render () {
        return <Card class={`skill-showcase skill-showcase-${this.props.type === "right" ? "left" : "right"}`} shadow={true}>
            {this.props.children}
            <Whitespace classes="pb-half">
                <Text type="title3" light={true} value={this.props.title} />
            </Whitespace>
            <Text light={true} value={this.props.body} />
        </Card>
    }
}