import React, { Component } from 'react';
import './style.css'

export default class Bubble extends Component {
    render () {
        const classList = ["bubble"];
        
        return <div className={classList.join(" ")}>
            {this.props.children}
        </div>
    }
}