import React, { Component } from 'react';

import './style.css'
import EmailForm from '../../molecules/EmailForm';
import Text from '../../atoms/Text';
import Whitespace from '../../atoms/Whitespace';

export default class CallToAction extends Component {
    render () {
        return <div className="call-to-action">
            <Text
                value="Enter your email below and I will reach out with further details"
                type="title1"
                dark={true}
            />
            <Whitespace classes="pt-3 pb-1">
                <EmailForm />
            </Whitespace>
            <Text
                value="I will never share your email"
                type="caption"
                gray={true}
            />
        </div>
    }
}