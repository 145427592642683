import React, { Component } from 'react';

import Whitespace from '../../atoms/Whitespace';
import './style.css';

export default class Nav extends Component {
    render () {
        const children = this.props.children;
        
        return <Whitespace>
            {children.map((child, index) => 
                <Whitespace key={index} classes={index !== children.length - 1 ? "pr-3 nav-mobile": ""}>
                    {child}
                </Whitespace>
            )}
            
        </Whitespace>
    }
}