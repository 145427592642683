import React, { Component } from 'react';

import './style.css';
import SkillShowcase from '../../molecules/SkillShowcase';
import Image from '../../atoms/Image';
import Card from '../../atoms/Card';

export default class SkillFeature extends Component {
    render () {
        const { type, last, title, body, StoryImage } = this.props;

        let classList = ["skill-feature"];
        if (type === 'right') {
            classList.push("skill-feature-right");
        }

        if (last) {
            classList.push("feature-last");
        }

        return <div 
            className={classList.join(" ")}
        >
            <SkillShowcase 
                title={title}
                body={body}
                type={type}
            >
                {this.props.children}
            </SkillShowcase>
            <Card 
                shadow={true}
                class={`skill-feature-image ${type === 'right' ? 'feature-right' : 'feature-left'}`}
            >
                <Image
                    name="design"
                    source={StoryImage}
                />
            </Card>
        </div>
    }
}