import React, { Component } from 'react';
import { GatsbyImage } from "gatsby-plugin-image"
import './style.css'

export default class Image extends Component {
    render () {
        var classList = ["image"];
        if (this.props.thumbnail) {
            classList.push("image-thumbnail");
        }

        if (this.props.class) {
            classList.push(this.props.class);
        }
        
        return <GatsbyImage 
            image={this.props.source} 
            alt={this.props.name} 
            className={classList.join(" ")}
        />
    }
}