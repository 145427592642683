import React, { Component } from 'react';
import './style.css';

import Story from '../../molecules/Story';
import StoryShowcase from '../../molecules/StoryShowcase';

class Hero extends Component {
    render () {
        return <div className="hero">
            <Story />
            <StoryShowcase />
        </div>
    }
}

export default Hero;