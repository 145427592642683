import React, { Component } from 'react';
import './style.css';

import Logo from '../../molecules/Logo';
import Nav from '../../molecules/Nav';
import Link from '../../atoms/Link';
import Button from '../../atoms/Button';

class Footer extends Component {
    render () {
        return <div className="footer">
            <Logo 
                title="AcumenApps" 
                white={true}
            />
            <Nav>
                <Link white={true} type="nav" value="Home" href="#home"/>
                <Link white={true} type="nav" value="What you can do?" href="#skills"/>
                <Link white={true} type="nav" value="Examples" href="#examples"/>
                <Button>
                    <Link type="white" value="Let's Talk" href="#cta"/>
                </Button>
            </Nav>
        </div>
    }
}

export default Footer;