import React, { Component } from 'react';
import Text from '../../atoms/Text';

export default class Logo extends Component {
    render () {
        return <Text 
            type="title2" 
            value={this.props.title} 
            white={this.props.white}
        />
    }
}