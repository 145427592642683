import React, { Component } from 'react';
import './style.css'

export default class Button extends Component {
    render () {
        const classList = ["button"];
        if (this.props.cta) {
            classList.push("button-cta")
        }

        if (this.props.form) {
            classList.push("button-form");
        }
        
        return <button 
            id={this.props.id} 
            className={classList.join(" ") + " " + this.props.classes}
            name={this.props.name}
        >
            {this.props.children}
        </button>
    }
}