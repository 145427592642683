import React, { Component } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp'

import './style.css'
import Input from '../../atoms/Input';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';

export default class EmailForm extends Component {

    constructor (props) {
        super(props);

        this.state = {
            email: '',
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const response = await addToMailchimp(this.state.email)
        if (response.result === "success") {
            this.setState({email: ''})
            document.getElementById("mce-success-response").innerHTML = "Thank you for your interest. I will reach out to you asap."
            document.getElementById("mce-error-response").innerHTML = ""
        } else {
            document.getElementById("mce-error-response").innerHTML = response.msg
            document.getElementById("mce-success-response").innerHTML = ""
        }
    }

    render () {
        return <div id="mc_embed_signup">
            <form 
                className="email-form validate" 
                id="mc-embedded-subscribe-form" 
                name="mc-embedded-subscribe-form"  
                noValidate
                onSubmit={this.handleSubmit}
            >
                <div id="mc_embed_signup_scroll">
                    <div className="mc-field-group" style={{textAlign: "center"}}>
                        <label htmlFor="mce-EMAIL">
                        <Input 
                            type="email"
                            name="EMAIL"
                            classes="required email"
                            placeholder="Email.." 
                            id="mce-EMAIL"
                            value={this.state.email}
                            onChange={e => this.setState({email: e.target.value})}
                        />
                        </label>
                        <Button 
                            form={true} 
                            cta={true} 
                            id="mc-embedded-subscribe"
                            name="subscribe"
                            classes="button"
                        >
                            <Text type="white" value="Let's Talk" />
                        </Button>
                    </div>
                </div>
                
                <div id="mce-responses" className="clear">
                    <div className="response" id="mce-error-response"></div>
                    <div className="response" id="mce-success-response"></div>
                </div>
            </form>
        </div>
        
    }
}