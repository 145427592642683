import React, { Component } from 'react';

import Text from '../../atoms/Text';
import Whitespace from '../../atoms/Whitespace';
import Skill from '../../molecules/Skill';
import './style.css';
import Icon from '../../../icons/arrow_right_alt.svg';

export default class Example extends Component {
    render () {
        return <div className="example">
            <Text type="title2" value={this.props.title} />
            <Whitespace classes="pt-1 pb-1">
                <Text value={this.props.body} />
            </Whitespace>
            <div className="example-skills">
                {this.props.skills.map(skill => 
                    <Whitespace classes="pr-1 pb-1 p-mobile" key={skill.id}>
                        <Skill name={skill.name} />
                    </Whitespace>
                )}
            </div>
            <Whitespace classes="pt-2">
                <a className="example-action" href="#cta">
                    <Text type="action" value="LET'S TALK " />
                    <Icon />
                </a>
            </Whitespace>
        </div>
    }
}